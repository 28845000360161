import React from 'react'
import { Container, Button } from 'reactstrap'
import { Link } from 'react-router-dom'

import Breadcrumb from '../../layout/breadcrumb'
import ActiveDemoAccounts from './accounts'

import { useTranslation } from 'react-i18next'

const DemoAccounts = () => {
  const { t } = useTranslation()
  return (
    <>
      <Breadcrumb
        title={t("demo.demoAccount")}
        action={
          <div className="pull-right">
            <Link to="/demo/register">
              <Button color="primary">{t("demo.register")}</Button>
            </Link>
          </div>
        }
      />
      <Container>
        <ActiveDemoAccounts />
      </Container>
    </>
  )
}

export default DemoAccounts
